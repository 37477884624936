import React from "react";
import Layout from "../components/layout/layout";
import Player from "../components/audio-player/player";
import sample1 from "../samples/1 to the 2.mp3";
import sample2 from "../samples/Uphill Battle.wav";
import sample3 from "../samples/01 - Rage +.mp3";
import sample4 from "../samples/05 - Got That Love Like.mp3";
import sample5 from "../samples/These Bones Have Life.mp3";
import sample6 from "../samples/01 - Metamorphic.mp3";
import "./samples.scss";

const SamplesPage = () => {
  return (
    <Layout
      title="Samples"
      description="This is the samples page."
    >
      <section className="samples">
        <div className="container">
          <Player
            artistName="Lyrically Poetic"
            songTitle="1 to the 2"
            link="https://open.spotify.com/track/1oTdAVbaW14JX2aNAX8Q4D?si=BukV04jARKqh9vueD7Yn-g"
            source={sample1}
          />
          <Player
            artistName="Lyrically Poetic"
            songTitle="Uphill Battle"
            link="https://open.spotify.com/track/1oTdAVbaW14JX2aNAX8Q4D?si=BukV04jARKqh9vueD7Yn-g"
            source={sample2}
          />
          <Player
            artistName="Jaspen feat. Ryan Luriea"
            songTitle="Rage +"
            link="https://open.spotify.com/track/3wjjKa6hyQAXMvX2M9wI0Z?si=bffZ_I5XQgGISXc37pcaDQ"
            source={sample3}
          />
          <Player
            artistName="Luke Icard"
            songTitle="Got That Love Like"
            link="https://open.spotify.com/track/5AMKEyiZL0Rgr80eS9x8eC?si=mZFOgnACQZWpNtHw-NYkrQ"
            source={sample4}
          />
          <Player
            artistName="Inches To Infinity"
            songTitle="These Bones Have Life"
            link="https://open.spotify.com/track/05q6jT1u8OlpxgU7CzIg6y"
            source={sample5}
          />
          <Player
            artistName="Inches To Infinity"
            songTitle="Metamorphic"
            link="https://open.spotify.com/track/1QLrCkDVCC8C6ULB9Ik0Vk?si=wVAlVJsYRnOu3Y2-CtslSA"
            source={sample6}
          />
        </div>
      </section>
    </Layout>
  );
};

export default SamplesPage;
