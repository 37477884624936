import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactAudioPlayer from "react-audio-player";

const Player = ({artistName, songTitle, link, source }) => {

  return (
      <div className="sample">
        <div className="artist-info">
          <p className="artist-name">Artist Name: {artistName}</p>
          <p className="track-title">Song Title: {songTitle}</p>
        </div>
        <a
          href={link}
          rel="noreferrer"
          target="_blank"
        >
          <FontAwesomeIcon icon={[ "fab", "spotify" ]}/>
        </a>
        <ReactAudioPlayer
          src={source}
          controls
        />
      </div>
  );
};

export default Player;
